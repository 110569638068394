@use 'three-dots' with (
  $dot-width: 7px,
  $dot-height: 3px,
  
);
.agents_panel {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    grid-auto-rows: 110px;
  }

.agents_panel_compact_view {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 10px;
    grid-auto-rows: 60px;
   
  
  }


  @keyframes call {
    15% {
        box-shadow: 0 0 0 1.5px rgba(150, 130, 223, 0.5);
    }
    25% {
        box-shadow: 0 0 0 3px rgba(150, 130, 223, 0.5),
                    0 0 0 6px rgba(150, 130, 223, 0.3);
    }
    30% {
        box-shadow: 0 0 0 5px rgba(150, 130, 223, 0.5),
                    0 0 0 10px rgba(150, 130, 223, 0.3);
    }
}

@keyframes call_compact {
  15% {
      box-shadow: 0 0 0 0.5px rgba(150, 130, 223, 0.5);
  }
  25% {
      box-shadow: 0 0 0 2px rgba(150, 130, 223, 0.5),
                  0 0 0 4px rgba(150, 130, 223, 0.3);
  }
  30% {
      box-shadow: 0 0 0 3px rgba(150, 130, 223, 0.5),
                  0 0 0 6px rgba(150, 130, 223, 0.3);
  }
}
.rotate_timer {
  transform: rotate(90deg); 
}

.vl {
  border-left: 3px solid rgb(158, 166, 158);
  height: 13px;
}