.queue_panel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    width: 100%;
    height: 100%;
  
  }

.queue_panel_agents {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px;
  width: 100%;
  height: 100%;
  }

  .align_center {
    height: "100vh";
    display: flex;
    justify-content: center;
    align-items: center;
  }

